import * as React from 'react';
import { StyledIcon } from './styled';
import { CSSObject } from '@emotion/react';

export interface IconProps {
    /**
     * Display size of the icon
     */
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';

    /**
     * Optionally set a different aspect ratio than the default 1x1
     * (will keep height and extend width)
     */
    aspectRatio?: number;

    /**
     * Optional label for assistive technology users
     */
    label?: string;

    /**
     * Adjust alignment of icon edges. Adds negative margins based on icon width
     *
     * Useful when you need to align with an edge of the actual graphics, not the SVG bounds. How much you'll
     * need to shift depends on the icon used.
     *
     * For instance `offset={{ right: 0.25 }}` subtracts 25% of the icon width at the right edge of the icon (using
     * negative margins, so any graphics overflow is still visible).
     *
     * Likewise `offset={{ bottom: 0.5 }}` will subtracts 50% of icon width below etc.
     */
    offset?: { top?: number; bottom?: number; left?: number; right?: number };

    /**
     * Add additional classNames to the icon wrapper.
     * Useful for extending the component using @emotion/styled
     */
    className?: string;

    css?: CSSObject;

    children?: React.ReactNode;
}

/**
 * Ex:
 *
 * import VDivider from '$icons/v-divider.svg';
 * <Icon size="lg"><VDivider /></Icon>
 */
export const Icon = React.forwardRef(
    ({ size = 'sm', offset, label, ...restProps }: IconProps, ref?: React.Ref<HTMLDivElement>) => {
        return (
            <StyledIcon
                ref={ref}
                size={size}
                css={{
                    '--icon-offset-top': offset?.top && offset?.top,
                    '--icon-offset-bottom': offset?.bottom && offset?.bottom,
                    '--icon-offset-left': offset?.left && offset?.left,
                    '--icon-offset-right': offset?.right && offset?.right,
                }}
                aria-label={label || undefined}
                aria-hidden={!label ? true : undefined}
                {...restProps}
            />
        );
    },
);
