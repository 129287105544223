import React, { ReactNode } from 'react';
import { ButtonSize, StyledButtonContent } from '~/shared/components';
import { VisuallyHidden } from '~/shared/components/VisuallyHidden';

export const Content = ({
    size,
    description,
    children,
}: {
    size: ButtonSize;
    description: string;
    children: ReactNode;
}) => {
    return (
        <>
            <StyledButtonContent size={size} aria-hidden="true">
                {children}
            </StyledButtonContent>
            <VisuallyHidden>{description}</VisuallyHidden>
        </>
    );
};
