import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { stripUnits } from '~/theme/utils/stripUnits';
import { IconProps } from './Icon';

// Size we assume all svgs will be having and from which we calculate the stroke
// width when it is displayed at different sizes
const ICON_BASE_SIZE = 30;

export const StyledIcon = styled.figure<IconProps>(
    ({ aspectRatio = 1 }) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: aspectRatio !== 1 ? `calc(var(--icon-size) * ${aspectRatio})` : 'var(--icon-size)',
        height: 'var(--icon-size)',
        marginTop: 'calc(var(--icon-size) * var(--icon-offset-top) * -1)',
        marginBottom: 'calc(var(--icon-size) * var(--icon-offset-bottom) * -1)',
        marginLeft: 'calc(var(--icon-size) * var(--icon-offset-left) * -1)',
        marginRight: 'calc(var(--icon-size) * var(--icon-offset-right) * -1)',
        flex: '0 0 auto',

        svg: {
            width: '100%',
            height: '100%',

            'path, circle, polygon, rect, line, polyline': {
                strokeWidth: '1.4',
            },
        },
    }),
    switchProp('size', {
        xs: ({ theme }) => ({
            '--icon-size': theme.sizes.iconXs,
            '--icon-stroke-width': `calc(${
                ICON_BASE_SIZE / stripUnits(theme.sizes.iconXs)
            } * var(--icon-stroke-thickness))`,
        }),
        sm: ({ theme }) => ({
            '--icon-size': theme.sizes.iconSm,
            '--icon-stroke-width': `calc(${
                ICON_BASE_SIZE / stripUnits(theme.sizes.iconSm)
            } * var(--icon-stroke-thickness))`,
        }),
        md: ({ theme }) => ({
            '--icon-size': theme.sizes.iconMd,
            '--icon-stroke-width': `calc(${
                ICON_BASE_SIZE / stripUnits(theme.sizes.iconMd)
            } * var(--icon-stroke-thickness))`,
        }),
        lg: ({ theme }) => ({
            '--icon-size': theme.sizes.iconLg,
            '--icon-stroke-width': `calc(${
                ICON_BASE_SIZE / stripUnits(theme.sizes.iconLg)
            } * var(--icon-stroke-thickness))`,
        }),
        xl: ({ theme }) => ({
            '--icon-size': theme.sizes.iconXl,
            '--icon-stroke-width': `calc(${
                ICON_BASE_SIZE / stripUnits(theme.sizes.iconXl)
            } * var(--icon-stroke-thickness))`,
        }),
        '2xl': ({ theme }) => ({
            '--icon-size': theme.sizes.icon2XL,
            '--icon-stroke-width': `calc(${
                ICON_BASE_SIZE / stripUnits(theme.sizes.icon3xl)
            } * var(--icon-stroke-thickness))`,
        }),
        '3xl': ({ theme }) => ({
            '--icon-size': theme.sizes.icon3xl,
            '--icon-stroke-width': `calc(${
                ICON_BASE_SIZE / stripUnits(theme.sizes.icon3xl)
            } * var(--icon-stroke-thickness))`,
        }),
    }),
);
