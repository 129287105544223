import React, { memo, ReactNode } from 'react';
import { Icon } from '../../Icon';
import ArrowRight from '$icons/arrow-right.svg';
import ArrowDown from '$icons/arrow-down.svg';
import ArrowLeft from '$icons/arrow-left.svg';
import Plus from '$icons/plus.svg';
import Location from '$icons/location.svg';
import Search from '$icons/search.svg';
import Send from '$icons/send.svg';
import Profile from '$icons/profile.svg';
import User from '$icons/user.svg';
import ResetCamera from '$icons/reset-camera.svg';
import ResetConfig from '$icons/reset-config.svg';
import Configurator from '$icons/configurator.svg';
import Screenshot from '$icons/screenshot.svg';

type ButtonIconProps = {
    iconLabel?: string;
    iconAlignment?: 'start' | 'end';
    iconOffsetEdge?: number;
    icon?: ReactNode | string;
    iconSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';
};

export const ButtonIcon = memo(
    ({ iconLabel, iconAlignment, iconOffsetEdge, icon, iconSize = 'sm' }: ButtonIconProps) => {
        if (typeof icon === 'string') {
            switch (icon.toLowerCase().trim()) {
                case 'arrow right':
                    icon = <ArrowRight aria-hidden="true" />;
                    break;
                case 'arrow down':
                    icon = <ArrowDown aria-hidden="true" />;
                    break;
                case 'arrow left':
                    icon = <ArrowLeft aria-hidden="true" />;
                    break;
                case 'plus':
                    icon = <Plus aria-hidden="true" />;
                    break;
                case 'pin':
                    icon = <Location aria-hidden="true" />;
                    break;
                case 'search':
                    icon = <Search aria-hidden="true" />;
                    break;
                case 'send':
                    icon = <Send aria-hidden="true" />;
                    break;
                case 'profile':
                    icon = <Profile aria-hidden="true" />;
                    break;
                case 'person':
                    icon = <User aria-hidden="true" />;
                    break;
                case 'reset-camera':
                    icon = <ResetCamera aria-hidden="true" />;
                    break;
                case 'reset-config':
                    icon = <ResetConfig aria-hidden="true" />;
                    break;
                case 'configurator':
                    icon = <Configurator aria-hidden="true" />;
                    break;
                case 'screenshot':
                    icon = <Screenshot aria-hidden="true" />;
                    break;
            }
        }

        return (
            <Icon
                label={iconLabel}
                size={iconSize || 'sm'}
                offset={{
                    left: iconAlignment === 'start' ? iconOffsetEdge : undefined,
                    right: iconAlignment === 'end' ? iconOffsetEdge : undefined,
                }}
                children={icon}
            />
        );
    },
);
